import React from "react";

import NotFoundPage from "pages/404Page";
import AppBarLayout from "layout/appBarLayout";
import RenderRoutes from "components/renderRoutes";

const ConnectComponent = React.lazy(() => import("pages/connect"));

const OnboardingInterestComponent = React.lazy(() =>
  import("pages/onboarding/interests")
);
const OnboardingReferralComponent = React.lazy(() =>
  import("pages/onboarding/referral")
);

const StakeComponent = React.lazy(() => import("pages/stake"));
const WalletComponent = React.lazy(() => import("pages/wallet"));
const SettingComponent = React.lazy(() => import("pages/settings"));
const DiscoverComponent = React.lazy(() => import("pages/discover"));
const AdWatchComponent = React.lazy(() => import("pages/adWatch"));
const AffiliateComponent = React.lazy(() => import("pages/settings/affiliate"));
const AccountSettingComponent = React.lazy(() =>
  import("pages/settings/account")
);
const InterestsSettingComponent = React.lazy(() =>
  import("pages/settings/interests")
);

export const INDEX_ROUTE = "/";
export const ONBOARDING_REFERRAL_ROUTE = "/onboarding-referral/";
export const ONBOARDING_INTEREST_ROUTE = "/onboarding-interests/";

export const STAKE_ROUTE = "/stake/";
export const WALLET_ROUTE = "/wallet/";
export const SETTINGS_ROUTE = "/settings/";
export const AFFILIATE_ROUTE = "/affiliate/";
export const ACCOUNT_SETTINGS_ROUTE = `${SETTINGS_ROUTE}account/`;
export const ACCOUNT_INTERESTS_ROUTE = `${SETTINGS_ROUTE}interests/`;
export const AD_WATCH_ROUTE = "/watch/:adId/";

export const getAdWatchRoute = (adId) => {
  return AD_WATCH_ROUTE.replace(":adId", adId);
};

export const NON_LOGIN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "connect",
        path: INDEX_ROUTE,
        component: ConnectComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "onboarding-interests",
        path: ONBOARDING_INTEREST_ROUTE,
        component: OnboardingInterestComponent,
      },
      {
        exact: true,
        name: "onboarding-referral",
        path: ONBOARDING_REFERRAL_ROUTE,
        component: OnboardingReferralComponent,
      },
      {
        exact: true,
        name: "ad-watch",
        path: AD_WATCH_ROUTE,
        component: AdWatchComponent,
      },
      {
        exact: true,
        name: "stake",
        path: STAKE_ROUTE,
        component: StakeComponent,
      },
      {
        path: "/",
        name: "app-bar-layout",
        component: AppBarLayout,
        routes: [
          {
            exact: true,
            name: "discover",
            path: INDEX_ROUTE,
            component: DiscoverComponent,
          },
          {
            exact: true,
            name: "settings",
            path: SETTINGS_ROUTE,
            component: SettingComponent,
          },
          {
            exact: true,
            name: "wallet",
            path: WALLET_ROUTE,
            component: WalletComponent,
          },
          {
            exact: true,
            name: "affiliate",
            path: AFFILIATE_ROUTE,
            component: AffiliateComponent,
          },
          {
            exact: true,
            name: "account-settings",
            path: ACCOUNT_SETTINGS_ROUTE,
            component: AccountSettingComponent,
          },
          {
            exact: true,
            name: "interests-settings",
            path: ACCOUNT_INTERESTS_ROUTE,
            component: InterestsSettingComponent,
          },
        ],
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];
