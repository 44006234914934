import { Fragment } from "react";
import { TabBar } from "antd-mobile";
import { useLocation } from "react-router-dom";
import { AppOutline, SetOutline, CollectMoneyOutline } from "antd-mobile-icons";

import { INDEX_ROUTE, SETTINGS_ROUTE, WALLET_ROUTE } from "routes";

import RenderRoutes from "components/renderRoutes";

function AppBarLayout({ history, routes }) {
  const location = useLocation();
  const { pathname } = location;

  const tabs = [
    {
      key: INDEX_ROUTE,
      title: "Discover",
      icon: <AppOutline />,
    },
    {
      key: WALLET_ROUTE,
      title: "Wallet",
      icon: <CollectMoneyOutline />,
    },
    {
      key: SETTINGS_ROUTE,
      title: "Settings",
      icon: <SetOutline />,
    },
  ];

  return (
    <Fragment>
      <RenderRoutes routes={routes} />
      <TabBar activeKey={pathname} onChange={(value) => history.push(value)}>
        {tabs.map((item) => (
          <TabBar.Item
            key={item.key}
            icon={item.icon}
            className="py-4"
            title={item.title}
          />
        ))}
      </TabBar>
    </Fragment>
  );
}

export default AppBarLayout;
