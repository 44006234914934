import { SpinLoading } from "antd-mobile";
import { useState, useEffect, Fragment } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { isEmpty } from "_dash";
import { getUserDetail } from "actions";
import { AUTHORIZATION_KEY } from "global_constants";
import { NON_LOGIN_ROUTES, LOGGED_IN_ROUTES } from "routes";

import RouteWithSubRoutes from "components/routeWithSubRoutes";

import AccountHOC from "hoc/account";
import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

import desktopImg from "assets/images/desktop.jpeg";

function App({ provider, accountData, setProvider, setAccountData }) {
  const [, , , connection, doAutoConnect, ,] = useConnect(provider);

  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState([]);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    getUserDetail()
      .then(async (response) => {
        const connectResponse = await doAutoConnect();
        if (
          !connectResponse ||
          (connectResponse && connectResponse.address !== response.address)
        ) {
          localStorage.removeItem(AUTHORIZATION_KEY);
        } else {
          setAccountData(response);
        }
        window.setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setAppRoutes(LOGGED_IN_ROUTES);
    } else {
      setAppRoutes(NON_LOGIN_ROUTES);
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <SpinLoading />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="app flex flex-col justify-between">
        <BrowserRouter>
          <Switch>
            {appRoutes.map((route, i) => {
              return <RouteWithSubRoutes key={i} {...route} />;
            })}
          </Switch>
        </BrowserRouter>
      </div>
      <div
        style={{
          backgroundImage: `url(${desktopImg})`,
        }}
        className="desktop-img fixed right-0 h-screen top-0 w-[80%] bg-no-repeat bg-cover z-10 bg-center"
      />
    </Fragment>
  );
}

export default ProviderHOC(AccountHOC(App));
