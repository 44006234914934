export const USER_API_PATH = "v/user/";

export const CONNECT_API_PATH = `${USER_API_PATH}connect/`;
export const SIGNATURE_API_PATH = `${USER_API_PATH}signature/`;
export const CLAIM_SIGNATURE_API_PATH = `${USER_API_PATH}claim_signature/`;

export const UPDATE_USER_API_PATH = `${USER_API_PATH}update_user/`;
export const USER_WEB3_EVENTS_API_PATH = `${USER_API_PATH}web3_events/`;
export const VALIDATE_REFERRAL_API_PATH = `${USER_API_PATH}validate_referral/`;
export const UPDATE_USER_INTERESTS_API_PATH = `${USER_API_PATH}update_interests/`;

export const USER_AD_BASIC_STATISTICS_API_PATH = `${USER_API_PATH}ad_basic_statistics/`;

export const INTERESTS_API_PATH = "interests/";
export const COUNTRIES_API_PATH = "countries/";

export const ADS_API_PATH = "ads/";
export const AD_DETAIL_API_PATH = `${ADS_API_PATH}{}/`;

export const AD_REPORT_API_PATH = `${AD_DETAIL_API_PATH}report/`;
export const AD_WATCH_EVENT_API_PATH = `${AD_DETAIL_API_PATH}watch/`;
export const AD_TRACK_EVENT_API_PATH = `${AD_DETAIL_API_PATH}track_event/`;
