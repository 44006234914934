import instance from "../axios";
import * as endpoints from "./endpoints";

import { AUTHORIZATION_KEY } from "global_constants";

export const getInterests = () => {
  return instance.get(endpoints.INTERESTS_API_PATH);
};

export const getCountries = () => {
  return instance.get(endpoints.COUNTRIES_API_PATH);
};

export const getSignature = (address) => {
  return instance.post(endpoints.SIGNATURE_API_PATH, { address });
};

export const connect = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.CONNECT_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const updateUser = (data) => {
  return instance.patch(endpoints.UPDATE_USER_API_PATH, data);
};

export const updateUserInterests = (data) => {
  return instance.post(endpoints.UPDATE_USER_INTERESTS_API_PATH, data);
};

export const getClaimSignatureData = (data) => {
  return instance.post(endpoints.CLAIM_SIGNATURE_API_PATH, data);
};

export const validateRefferal = (data) => {
  return instance.post(endpoints.VALIDATE_REFERRAL_API_PATH, data);
};

export const getUserAdBasicStatistics = () => {
  return instance.get(endpoints.USER_AD_BASIC_STATISTICS_API_PATH);
};

export const getUserWeb3Events = () => {
  return instance.get(endpoints.USER_WEB3_EVENTS_API_PATH);
};

export const getAds = (urlParamsString) => {
  return instance.get(endpoints.ADS_API_PATH.concat("?", urlParamsString));
};

export const getAdDetail = (adId) => {
  return instance.get(endpoints.AD_DETAIL_API_PATH.replace("{}", adId));
};

export const createAdTrackEvent = (adId, data) => {
  return instance.post(
    endpoints.AD_TRACK_EVENT_API_PATH.replace("{}", adId),
    data
  );
};

export const createAdWatchEvent = (adId, data) => {
  return instance.post(
    endpoints.AD_WATCH_EVENT_API_PATH.replace("{}", adId),
    data
  );
};

export const reportAd = (adId, data) => {
  return instance.post(endpoints.AD_REPORT_API_PATH.replace("{}", adId), data);
};
