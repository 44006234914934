import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd-mobile";

import { ProviderProvider } from "./context/provider";
import { providerReducer, initialState as provider } from "./reducers/provider";

import {
  accountDataReducer,
  initialState as accountData,
} from "./reducers/accountDataReducer";
import { AccountDataProvider } from "./context/accountData";

import enUS from "antd-mobile/es/locales/en-US";
import App from "./App";

import "index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={enUS}>
    <AccountDataProvider
      initialState={accountData}
      reducer={accountDataReducer}
    >
      <ProviderProvider initialState={provider} reducer={providerReducer}>
        <App />
      </ProviderProvider>
    </AccountDataProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
