// import { Spin } from "antd";
import { Suspense } from "react";
import { SpinLoading } from "antd-mobile";
import { Switch, Route } from "react-router-dom";

import NotFoundPage from "../../pages/404Page";
import RouteWithSubRoutes from "../routeWithSubRoutes";

const RenderRoutes = ({ routes }) => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <SpinLoading />
        </div>
      }
    >
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.name} {...route} />;
        })}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default RenderRoutes;
